export default {
  mixRecharge: ["Vui lòng điền địa chỉ email"],
  language: "Ngôn ngữ",
  common: ["Dịch vụ khách hàng trực tuyến", "Hủy trục trặc", "Xác nhận", "Hủy"],
  upload: ["Đang tải lên...", "Định dạng sai", "Tải lên thành công", "Tải lên thất bại"],
  vanPull: ["", "Không có dữ liệu"],
  login: {
    text: ["Ngôn ngữ", "Mlink", "Đăng nhập"],
    label: ["Đang đăng nhập...", "Đăng nhập ngay"],
    placeholder: ["Vui lòng nhập email", "Vui lòng nhập mật khẩu đăng nhập"],
    default: ["Chưa có tài khoản?", "Đăng ký ngay","Nếu gặp khó khăn, vui lòng liên hệ với nhân viên chăm sóc khách hàng", "Quên mật khẩu?"],
    codes: ["Tài khoản không tồn tại", "Mật khẩu không đúng", "Tài khoản bị khóa", "Thất bại"],
    text2: ['Email', 'Xác thực', 'Mật khẩu', 'Mời', 'Điện thoại'],
    text1: ['Đăng nhập', 'Chào mừng bạn đến với trang web', 'Email hoặc điện thoại', 'Mật khẩu', 'Đăng nhập']
  },
  register: {
    text: [
      "Mlink",
      "Đang gửi mã xác thực...",
      "Nhấn để lấy",
      "Đang đăng ký...",
      "Đăng ký ngay",
    ],
    placeholder: [
      "Vui lòng nhập email",
      "Vui lòng nhập mã xác thực",
      "Vui lòng nhập mật khẩu đăng nhập",
      "Vui lòng xác nhận mật khẩu của bạn",
      "Vui lòng nhập mã mời",
      "Hai lần nhập mật khẩu không giống nhau",
      "Vui lòng nhập mã xác thực",
      "Email không hợp lệ"
    ],
    label: ["Đã có tài khoản?{a} {line}", "Quay lại đăng nhập"],
    codes:["Tài khoản đã tồn tại", "Không có mã xác thực", "Mã xác thực không đúng", "Hai lần nhập mật khẩu không nhất quán", "Người giới thiệu không tồn tại", "Thất bại"]
  },
  resetpwd:["Quên mật khẩu","Vui lòng nhập email","Vui lòng nhập mã xác thực","Nhấn để lấy","Vui lòng nhập mật khẩu","Đặt lại mật khẩu"],
  footer: ["Trang chủ", "Sảnh", "Đơn hàng", "Tôi"],

  home: {
    label:["Chào mừng"],
    broadcast: "Chúc mừng thành viên {member} trở thành {grade}",
    menu: ["Nhật ký tiền tệ", "Hướng dẫn cho người mới", "Mời đăng ký", "Liên hệ dịch vụ chăm sóc khách hàng"],
    noticeTitle: "Thông báo mới nhất",
    msg: "Công việc chưa được mở",
    video: "Chưa có video hướng dẫn",
    showmore: "show more",
    contents: ['Giao dịch C2C',
        'Đây là bản sao giới thiệu hai dòng tốt nhất!',
        'Giao dịch nhanh chóng',
        'Đây là bản sao giới thiệu hai dòng tốt nhất!',
        'Một sàn giao dịch tiền ảo có phù hợp với mọi người không?',
        'Khách hàng',
        'Các quốc gia được hỗ trợ',
        'Khối lượng giao dịch hàng quý',
        '4 bước để hoàn thành việc mua tiền ảo',
        'Tạo tài khoản sàn giao dịch miễn phí của bạn',
        'An toàn đầy đủ',
        'Kết nối các tùy chọn tài chính của bạn',
        'Mua và bán hơn 200 loại tiền ảo',
        'Thêm',
        'Trung tâm giao dịch',
        'Thị trường giao dịch sôi động, An toàn, Đáng tin cậy, bảo mật thông tin. Nền tảng chúng tôi luôn chào đón sự tham gia của bạn',
        'Ngoài ra còn có các đơn hàng phúc lợi bí ẩn với tỷ lệ lợi nhuận lên đến 20%',
        'Nhập trung tâm giao dịch',
        'Những cam kết và',
        'Dịch vụ của chúng tôi',
        'An toàn là hàng đầu, khách hàng là trọng tâm. Để đảm bảo',
        'an toàn của tiền của bạn, chúng tôi sẽ tiếp tục cố gắng',
        'Mô tả tiền dự trữ',
        'Chúng tôi cam kết rằng sàn giữ tất cả tài sản của người dùng',
        'với tỷ lệ dự trữ ít nhất là 1:1.',
        'Nơi lưu trữ lạnh an toàn',
        'Chúng tôi lưu trữ phần lớn tài sản kỹ thuật số của mình',
        'trong ví đa chữ ký ngoại tuyến.',
        'Quỹ bảo vệ người dùng',
        'Chúng tôi có một quỹ bảo vệ người dùng trị giá 300',
        'triệu USD, tạo ra một lớp bảo vệ bổ sung cho các rủi ro bảo mật tiềm ẩn.',
        'An toàn kỹ thuật,',
        'độ ổn định và độ tin cậy',
        'Chúng tôi có một nhóm kỹ thuật hàng đầu tập trung vào tất cả các khía cạnh Bảo vệ an toàn, động cơ hiệu quả được phát triển độc lập, đảm bảo hệ thống vẫn hoạt động đáng tin cậy trong các đơn hàng lớn.',
        'Chăm sóc khách hàng là điều tiên quyết',
        'Triết lý',
        'Đích đến thị trường toàn cầu, hỗ trợ nhiều ngôn ngữ. Dịch vụ khách hàng trực tuyến 7 * 24 giờ, dịch vụ khách hàng chuyên nghiệp.',
        'Toàn cầu Hoạt động',
        'Nhóm hoạt động toàn cầu, với nhiều năm kinh nghiệm trong lĩnh vực blockchain và tài chính Kinh nghiệm kinh doanh, mở rộng thị trường toàn cầu đến hơn 50 vùng',
        'Khuôn khổ hoạt động phong phú',
        'Các hoạt động vận hành thú vị, thưởng cao và dễ dàng',
        'Các câu hỏi thường gặp',
        'Nhóm nhân sự lõi của sàn của chúng tôi đến từ các doanh nghiệp Internet và tài chính nổi tiếng trên thế giới, bao gồm nhiều người đi đầu và nhà đầu tư trong ngành tài sản kỹ thuật số, và có kinh nghiệm phong phú trong nghiên cứu và phát triển công nghệ và hoạt động toàn cầu trong',
        'Các Câu Hỏi Thường Gặp',
        'Chúng tôi đã tập trung vào tiền điện tử hơn mười năm và chỉ cung cấp các dịch vụ an toàn, ổn định và có lợi nhuận. Chúng tôi có hơn 20 chuyên gia quản lý tài sản hàng đầu. Logic thay đổi nhanh chóng theo thời gian không nên được coi là đơn giản tạo ra hoặc thử nghiệm lớp nền tảng. Đây là một hệ thống tiêu chuẩn thực hiện một bước nhảy vọt mới thông qua giao dịch môi giới token.',
        'Sau khi trở thành thành viên của nền tảng BITCOIN, bạn có thể mua tiền điện tử thông qua các sàn giao dịch lớn và bán chúng trên nền tảng BITCOIN. Giá bán trên nền tảng BITCOIN sẽ cao hơn từ 3%-10% so với điểm lợi nhuận bán trên các sàn giao dịch. Ví dụ, bạn có thể mua 1000 USDT qua bất kỳ sàn giao dịch nào như Upbit, Coinone, Coinbase, v.v., và bán cho người ở các quốc gia bị cấm qua nền tảng BITCOIN, kiếm được lợi nhuận từ 3-10%. Điều này có nghĩa là bạn có thể kiếm được từ 30-100 USDT.',
        'Chúng tôi là ai?',
        'Mô hình thu nhập từ thành viên nền tảng？',
    ]
  },
  hall:{
    default:[
        "Sảnh giao dịch", "Số dư", "Tôi muốn mua", "Tôi muốn bán", "Xác nhận bán",
      "Danh sách đơn hàng", "Đơn hàng phúc lợi", "Đơn hàng hợp tác bán", "Đang chờ đặt giá", "Mua vào", "Đi bán"
    ],
    list:[
        'Số lượng', 'Đơn giá', 'Chưa thành công', 'Bán USDT', 'Vui lòng nhập số lượng USDT', 'Số tiền thu được', "Giới hạn", 'Mua USDT', 'Số tiền chi phí'
    ],
    kbip:["Hủy bỏ", "Vượt quá số tiền", "Phải nằm trong phạm vi số tiền"],
    btn:['Bán','documentary']
  },
  msglist:[
    "Danh sách tin nhắn"
  ],
  sell:{
      placeholder:["Vui lòng nhập giá bán", "Vui lòng nhập số lượng bán"],
      label:["Số dư (USDT)", "Họ và tên", "Số tài khoản ngân hàng", "Giá hiện tại (USDT)", "Giá bán tốt nhất", "Giá bán", "Số lượng bán", "Giá tốt nhất", "Tất cả", "Xác nhận bán"]
  },
  buy:{
    placeholder:["Vui lòng nhập giá mua", "Vui lòng nhập số lượng mua", "Giới hạn nhỏ nhất", "Giới hạn lớn nhất"],
    label:["Số dư (USDT)", "Họ và tên", "Số tài khoản ngân hàng", "Giá hiện tại (USDT)", "Giá mua tốt nhất", "Giá mua", "Số lượng mua", "Giá tốt nhất", "Tất cả", "Xác nhận mua", "Giới hạn"]
  },
  user: {
    default: [
      "Trung tâm cá nhân",
      "Tài khoản đăng nhập",
      "Mã mời",
      "Đăng xuất",
      "Số dư",
      "Vàng",
      "Ví",
      "Cài đặt",
      "Lịch sử giao dịch",
    ],
    menu: [
      "Tài sản của tôi",
      "Danh sách nhóm",
      "Kết nối tài khoản",
      "Chi tiết tài khoản",
      "Giới thiệu sàn",
      "Đăng xuất"
    ],
  },
  bindAccount: {
    default: ["Kết nối tài khoản","Nộp"],
    fields: ["Họ và tên tài khoản", "Số điện thoại", "Số tài khoản ngân hàng","Tên ngân hàng","Loại","Địa chỉ USDT"],
    placeholder: ["Vui lòng nhập họ và tên mở tài khoản","Vui lòng nhập số điện thoại","Vui lòng nhập số tài khoản ngân hàng","Vui lòng nhập tên ngân hàng mở tài khoản",
      "Vui lòng chọn loại USDT","Vui lòng nhập địa chỉ USDT"],
  },
  wallet: {
    default: [
      "Tài sản của tôi",
      "Nạp tiền",
      "Rút tiền",
      "Lịch sử nạp tiền",
      "Lịch sử rút tiền",
      "Số tiền đã nạp",
      "Số tiền đã bán",
      "Lợi nhuận đã thu được",
      "Số dư tài khoản",
      "Số dư",
      "Số tiền nạp (USDT)",
      "Số tiền bán",
      "Tổng lợi nhuận",
        "Nâng cấp thương gia",
      "Nâng cao cấp độ thương gia, vui lòng Liên hệ dịch vụ chăm sóc khách hàng",
        "Xác nhận vào sàn thương gia",
        "Xác thực thương gia",
      "Bạn cần phải thanh toán","Phiếu thanh toán","Tiền đặt cọc thương gia","Xác nhận ngay","Thông tin","Bạn cần phải thanh toán","Số dư không đủ, có muốn nạp tiền không",
        "Tổng số tiền nạp",
        "Thương gia",
        "Thương gia Vương miện",
    ],
    label: [
      "Phương thức rút tiền",
      "Số tiền rút ",
      "Mật khẩu tài chính",
      "Nộp",
      "Số điện thoại",
      "Email",
      "Mã IFSC",
      "Xác nhận",
        "Rút USDT",
        "Rút ",
    ],
    placeholder: [
      "Chọn phương thức rút tiền",
      "Vui lòng nhập số tiền rút",
      "Vui lòng nhập mật khẩu tài chính",
      "Vui lòng chọn phương thức rút tiền",
      "Vui lòng nhập số điện thoại người nhận",
      "Vui lòng nhập email người nhận",
      "Vui lòng nhập mã IFSC của người nhận",
    ],
    msg: [
      "Bạn vẫn chưa thiết lập mật khẩu tài chính, vui lòng thiết lập trước",
      "Bạn vẫn chưa kết nối tài khoản PIX, vui lòng kết nối trước",
      "Bạn có chắc muốn rút tiền không?"
    ],
  },
  recharge: {
    default: [
      "Nạp tiền vào ví",
      "Tiền nạp trước vào ví",
      "Phương thức nạp tiền",
        "Nạp tiền",
        "Xác nhận",
    ],
    label: [
      "Loại",
      "Địa chỉ",
      "Số tiền nạp",
      "Nhập số tiền",
      "Vui lòng cung cấp hóa đơn",
        "Sao chép thành công",
        "Sàn giao dịch tiền nạp trước",
        "Nạp tiền"
    ],
    placeholder: [
      "Loại",
      "Địa chỉ",
    ],
    info: [
      "Số tiền nạp",
      "Số đơn hàng",
      "Ngân hàng nhận tiền",
      "Số tài khoản nhận tiền",
      "Người nhận tiền",
      "Sao chép"
    ]
  },
  task: {
    tabs: ["Tất cả","Đang tiến hành", "Đã hết hiệu lực", "Đã hoàn thành","Đang chờ thương gia thanh toán","Đang chờ xác nhận"],
    default: ["Danh sách đơn hàng",],
    msg: ["Đang xác nhận", "Đã nộp, vui lòng chờ kiểm duyệt", "Thất bại"],
  },
  userInfo: {
    default: [
      "Thông tin cá nhân",
      "Ảnh đại diện",
      "Tài khoản",
      "Email",
      "Điểm tín dụng",
      "Thông tin chi tiết",
      "Đổi mật khẩu",
      "Mật khẩu tài chính",
      "Nhấn để thiết lập",
      "Đổi ảnh đại diện",
      "Đổi mật khẩu đăng nhập",
      "Đổi mật khẩu tài chính",
      "Nộp",
      "Xóa bộ nhớ đệm",
      "Xem thông tin",
      "Tên tài khoản",
      "Mã giới thiệu",
      "Vui lòng tải ảnh cần thay đổi"
    ],
    label: [
      "Mật khẩu đăng nhập cũ",
      "Mật khẩu đăng nhập mới",
      "Xác nhận mật khẩu",
      "Mật khẩu tài chính cũ",
      "Mật khẩu tài chính mới",
      "Xác nhận mật khẩu",
      "Lưu"
    ],
    placeholder: [
      "Vui lòng nhập mật khẩu đăng nhập cũ",
      "Vui lòng nhập mật khẩu đăng nhập mới",
      "Vui lòng xác nhận mật khẩu đăng nhập",
      "Vui lòng nhập mật khẩu tài chính cũ",
      "Vui lòng nhập mật khẩu tài chính mới",
      "Vui lòng xác nhận mật khẩu tài chính",
    ],
  },
  fundRecord: {
    default: ["Lịch sử chi phí", "Lịch sử nạp tiền", "Lịch sử giao dịch", "Nạp", "Nhận", "Chi"],
    tabs: ["Thu nhập", "Chi phí", "Nạp tiền"],
  },
  dialog: [
    "Thông báo",
    "Xác nhận",
    "Đang nộp...",
    "Sao chép thành công",
    "Hệ điều hành iOS phiên bản thấp không hỗ trợ",
    "Đang đăng ký...",
    "Đang tải dữ liệu...",
     "Đang tải...",
  ],
  serviceCenter: [
    "Trung tâm dịch vụ khách hàng",
    "Chào, tôi là nhân viên dịch vụ khách hàng đặc biệt~",
    "Rất vui được phục vụ bạn",
    "Dịch vụ tự động",
    "Dịch vụ khách hàng trực tuyến",
    "Dịch vụ khách hàng nạp tiền",
    "Dịch vụ khách hàng Line"
  ],
  userTaskRecord: [
    "Đơn hàng của tôi",
    "Đơn hàng bán",
    "Đơn hàng mua",
    "Trạng thái hiện tại",
    "Lợi nhuận hoa hồng",
    "Đã hoàn thành"
  ],
  withdrawlist: [
    "Lịch sử rút tiền",
  ],
  teamReport: {
    default: [
      "Danh sách nhóm",
    ],
  },
  common2:['Hướng dẫn cho người mới',"Liên hệ dịch vụ chăm sóc khách hàng","Điều khoản dịch vụ, xem chi tiết","Chăm sóc khách hàng 1","Nhân viên dịch vụ khách hàng 2"],
  common3:['Thành công',"Giới thiệu nền tảng"],
  invite:['Chia sẻ và quảng cáo','Mã mời của tôi',"Sao chép mã mời, mời bạn bè khác tham gia","Sao chép","Vào thời điểm này không thể mời thành viên"],
  common4:['Xác thực danh tính thật',"Nộp thành công","Những điều cần lưu ý","Nội dung điều cần lưu ý","Nộp tiền","Mặt trước thẻ căn cước công dân",
      "Chứng từ tài chính","Vui lòng đi kết nối tài khoản trước","Vui lòng hoàn thành xác thực danh tính thật trước"],
  common5:["Bán","Phút","Trước","Lưu mã QR","Mở","Tài khoản bị khóa, không thể giao dịch tạm thời","Tải xuống ứng dụng"],
  common6:["Đơn hàng nạp tiền","Số đơn hàng","Số tiền","Thời điểm","Trạng thái"],
  hall2:["Số tiền bán thấp nhất là","","Số lần bán vượt quá giới hạn mỗi ngày","Đơn hàng của tôi","Lịch sử giao dịch","Đơn hàng đặt giá","Mua","Bán"],
  register2:["Đăng ký bằng email","Đăng ký bằng điện thoại","Vui lòng nhập số điện thoại"],
  withdraw2: ["Rút tiền", "Tài khoản người bán", "Số dư tài khoản", "Số tiền cần thanh toán", "Thanh toán ngay", "Vẫn còn công việc chưa hoàn thành, không thể rút tiền tạm thời", "Số lần rút tiền mỗi ngày vượt quá giới hạn", "Ghi chú giải thích"],
  mytask2: ["Tài khoản thương gia", "Giá", "Số tiền", "Số dư tài khoản", "Số tiền cần thanh toán", "Thời điểm khớp", "Chi tiết đơn hàng", "Số lượng không thể là 0", "Số dư không đủ", "Giá không thể là 0", "Lỗi phạm vi"],
  taskOrder2: ["Bạn vẫn còn có đơn hàng chưa thành công, không thể hủy", "Số dư không đủ", "Xác nhận", "Thất bại", "Bạn vẫn cần hoàn thành đơn hàng để có thể rút tiền:"],
  busAuth2: ['Nâng cao cấp độ thương gia', 'Tôi đồng ý', "Xác nhận nâng cao cấp độ", "Nâng cao cấp độ thương gia", "Nếu cần phải sửa đổi thông tin thẻ ngân hàng, vui lòng Liên hệ dịch vụ chăm sóc khách hàng", "Tài khoản hiện tại cần phải nâng cao lên cấp độ thương gia"],
  recharge2: ['Số tiền không thể là 0', 'Phải tải lên ảnh chụp'],
  buy3: ["Đếm ngược", "Giờ", "Loại", 'Đơn hàng phúc lợi', 'Đơn hàng hợp tác bán', 'Giới hạn số người', 'Nếu không nhập thì không giới hạn số người', 'ID người dùng được chỉ định', 'Vui lòng nhập ID được chỉ định','Đơn hàng trên nền tảng'],
  hall3: ["Ngày", "Giờ", "Phút", "Giây", "Đơn hàng hợp tác bán", "Tài khoản", 'Đã hết hạn'],
  sell3: ['Giá bán không thể lớn hơn giá tốt nhất'],
  busAuth3: ["Thành viên", "Thương gia", "Thương gia vương miện"],
  recharge3: ["Chuyển đổi"],
  home3: ["Máy khai thác", "Đánh giá", "Hộp mù", "Quản lý tài chính", "Chờ đợi với sự mong đợi"],
  home4: ["Giao dịch nhanh", "Mua USDT bằng một cú nhấn", "Giao dịch C2C", "Mua và bán USDT", 'Người dùng trực tuyến', "Tổng lượng giao dịch trong 24 giờ (USDT)"],
  common7: ["Nếu chưa giải quyết được vấn đề của bạn, vui lòng Liên hệ dịch vụ chăm sóc khách hàng trực tuyến", "Liên hệ dịch vụ chăm sóc khách hàng", "Nhấn để tư vấn", "Cài đặt", "Tất cả", "Mời bạn bè", "Số lượng thành viên trong nhóm", "Số lượng mới trong tháng này", "Thời điểm đăng ký", "Số lượng người dưới quyền"],
  hall4: ['Vị trí của bạn', 'Thành viên', "Thông tin tài khoản của bạn", "Giao dịch thành công", "Bạn đã hoàn thành thành công", "Tổng giá", "Tìm mua", "Bán"],
  task3: ["Tất cả", "Đang tiến hành", "Đã hết hiệu lực", "Đã hoàn thành"],
  my: ["Bạn có chắc chắn muốn đăng xuất tài khoản không?"],
  bindAccount2: ["Vui lòng xác nhận rằng thông tin đã điền không có sai sót, nếu không sẽ ảnh hưởng đến giao dịch bình thường", "Kết nối ngay", "Liên hệ dịch vụ chăm sóc khách hàng"],
  recharge4: ["Để đảm bảo số tiền nạp của bạn hoàn thành một cách nhanh chóng, sau khi chuyển tiền thành công, bạn vui lòng tải hình ảnh hóa đơn lên để chúng tôi xác nhận"],
  resetpwd2: ['Email', 'Điện thoại', 'Chú ý: Mật khẩu được tạo từ 6 đến 16 ký tự, không phân biệt chữ hoa chữ thường'],
  home2: ["Đánh giá đặc biệt", "Thị trường hàng hóa có sẵn"],
  login1: ["Bước tiếp theo", "Đăng nhập ngay", "Có vẻ như bạn vẫn chưa có tài khoản. Hãy tạo một tài khoản mới:", "Đồng ý và tiếp tục"],
  login2: ["Nhiều cách đăng nhập khác", "Tiếp tục sử dụng ", "Facebook", "Google", "Apple"],
  service2: ["Giao dịch", "Khác"],
  buy2: ["Loại đơn hàng", "Mua thành công", "Mua USDT thành công"],
  sell4: ["Bạn xác nhận việc bán lần này", "Xác thực", "Bán thành công", "Bạn đã bán thành công", "Đang chờ người mua thanh toán", "Hoàn thành"],
  hall5: ["Thành viên"],
  busauth: ["Xác thực thông tin danh tính", "Vui lòng nhập tên thật của bạn", "Vui lòng nhập số thẻ căn cước công dân của bạn", "Tải lên ảnh",
     "Tải lên thông tin danh tính", "Chi tiết tiền gửi của người bán"],
  recharge5: ["Bấm để tải lên", "Nạp tiền thành công", "USDT đã được nạp thành công vào tài khoản", "Xác Nhận"],
  note: ["Những điều cần lưu ý", "Thông báo", "Số điện thoại", "Vui lòng nhập số điện thoại của bạn", "Nhắn tin", "Vui lòng để lại tin nhắn của bạn ở đây", "Tải lên ảnh", "Bấm để tải lên", "Nộp thành công", "Chúng tôi sẽ liên hệ với bạn càng sớm càng tốt", "Đóng"],
  notelist: [ "Danh sách tin nhắn" ],
  search: ["Tìm kiếm", "Không tìm thấy dữ liệu", "Vui lòng nhập từ khóa để tìm kiếm"],
  searchs: {orders: "Đơn hàng", recharge: "Nạp tiền", withdraw: "Rút tiền"},
  team2: ["Chi tiết nhóm", "Mời bạn bè"],
  task4: ["Công việc của tôi"],
  lang3: ["Tiếng Anh", "Tiếng Hàn", "Tiếng Trung Quốc繁體"],
  login3: ["Tài khoản hoặc mật khẩu sai, vui lòng nhập lại"],
  shiming: ['Kiểm tra thuế'],
  sidebar: ['Trang chủ',
      'Sảnh giao dịch',
    'Tài sản của tôi',
    'Liên kết ngân hàng',
    'Lịch sử giao dịch',
    'Danh sách nhóm',
    'Mời bạn bè',
    'Tải ứng dụng APP',
    'Chọn ngôn ngữ',
    'Màu chủ đề',
    'Giới thiệu nền tảng',
    'Thoát'],
  appDown: {
    default: [
      'Download App',
      'Android Download',
      'Apple Download',
    ]
  },
  memberRrading: {
    default: [
      'Giao dịch giữa bạn bè',
      "Thời gian giao dịch",
      "Loại giao dịch",
      "Bạn bè không có dữ liệu giao dịch",
        'Số tiền giao dịch'
    ]
  },
  help: {
    default: [
      'Lý do thành lập công ty BITCOIN',
      'Theo các báo cáo, các quốc gia và khu vực đã công bố lệnh cấm tuyệt đối bao gồm Algeria, Bangladesh, Trung Quốc, Ai Cập, Iraq, Ma-rốc, Nepal, Qatar và Tunisia. Tuy nhiên, đối với thị trường khổng lồ này, các sàn giao dịch lớn trên toàn cầu vẫn không thể từ bỏ những thị trường này, vì vậy nền tảng môi giới bên thứ ba BITCOIN ra đời.',
      'Hệ thống backend của nền tảng môi giới bên thứ ba BITCOIN:',
      'Nền tảng môi giới bên thứ ba BITCOIN, được tạo ra với sự phê duyệt của các sàn giao dịch lớn như Coinbase, Binance, Crypto, v.v.',
      'Mô hình lợi nhuận của nền tảng môi giới bên thứ ba:',
      'Tại các quốc gia cấm đăng nhập hoặc đăng ký tích hợp nền tảng môi giới bên thứ ba BITCOIN, người dùng không thể mua tiền điện tử qua các sàn giao dịch của chính họ. Tại các quốc gia cấm tích hợp nền tảng môi giới bên thứ ba BITCOIN, những người cần mua tiền điện tử chủ yếu đăng ký nền tảng môi giới bên thứ ba BITCOIN, trở thành người dùng thương mại và đặt đơn hàng để mua tiền điện tử qua nền tảng BITCOIN. Mỗi giao dịch sẽ bị tính phí dựa trên nhu cầu và số lượng đơn hàng.',
      'Mô hình lợi nhuận',
      'Sau khi trở thành thành viên của nền tảng BITCOIN, bạn có thể mua tiền điện tử thông qua các sàn giao dịch lớn và bán chúng trên nền tảng BITCOIN. Giá bán trên nền tảng BITCOIN sẽ cao hơn từ 3%-10% so với điểm lợi nhuận bán trên các sàn giao dịch. Ví dụ, bạn có thể mua 1000 USDT qua bất kỳ sàn giao dịch nào như Upbit, Coinone, Coinbase, v.v., và bán cho người ở các quốc gia bị cấm qua nền tảng BITCOIN, kiếm được lợi nhuận từ 3-10%. Điều này có nghĩa là bạn có thể kiếm được từ 30-100 USDT.',
      'Mô tả các phương thức',
      'Phương thức 1: Nền tảng tự động ghép nối các đơn hàng bán và điều chỉnh số lượng đơn hàng và tỷ lệ lợi nhuận dựa trên cấp độ VIP của thành viên và số lượng đơn hàng bán mỗi ngày.\n',
      'Ví dụ:',
      'VIP1 có thể tự động ghép nối đơn hàng bán mỗi ngày và kiếm 3% doanh thu từ các đơn hàng.',
      'VIP2 có thể tự động ghép nối và bán 2 đơn hàng mỗi ngày, kiếm được 5% doanh thu từ các đơn hàng này.',
      'VIP3 có thể tự động ghép nối bán 3 đơn hàng mỗi ngày, và bạn có thể nhận được 7% doanh thu bán hàng như lợi nhuận bán hàng.',
      'VIP4 có thể tự động ghép nối bán 4 đơn hàng mỗi ngày và có thể bán với 9% doanh thu bán hàng.',
      'VIP5 có thể tự động ghép nối và bán 5 đơn hàng mỗi ngày, kiếm được 10% doanh thu từ các đơn hàng này.',
      'Phương thức 2: Mua nhóm',
      'Số lượng tiền điện tử mà các đại lý nhượng quyền toàn cầu mua trong khu vực BITCOIN mỗi ngày là khác nhau, và tỷ lệ lợi nhuận của mỗi đại lý cũng khác nhau. Một số đại lý đưa ra tỷ lệ lợi nhuận rất cao cho các giao dịch mua khẩn cấp. Khi một số thương nhân cần mua gấp, tỷ lệ lợi nhuận sẽ cao hơn so với doanh thu bán hàng được ghép nối tự động. Điểm đặc biệt là không có giới hạn đối với các đơn hàng bán nhóm và bạn có thể thực hiện mỗi ngày. Chỉ cần có người bán có thể mua, các thành viên nền tảng có thể bán.',
      'Phương thức 3: Kinh doanh chênh lệch giá',
      'Giao dịch chênh lệch giá là nơi các nhà đầu tư toàn cầu hợp tác với hệ thống trí tuệ nhân tạo của chúng tôi để cung cấp dịch vụ giao dịch chênh lệch giá giữa các đồng tiền toàn cầu.',
      'Đây là một chương trình sử dụng trí tuệ nhân tạo (AI) để tự động ghép nối các hệ thống giao dịch của các nhà đầu tư ở các khu vực khác nhau và có thể tạo ra lợi nhuận bất cứ lúc nào. Đây là một chương trình giao dịch tự động, sử dụng công nghệ giao dịch Martin để thực hiện giao dịch và tạo ra lợi nhuận.',
      'Giới thiệu công ty',
      'Chúng tôi đã tập trung vào tiền điện tử hơn mười năm và chỉ cung cấp các dịch vụ an toàn, ổn định và có lợi nhuận. Chúng tôi có hơn 20 chuyên gia quản lý tài sản hàng đầu. Logic thay đổi nhanh chóng theo thời gian không nên được coi là đơn giản tạo ra hoặc thử nghiệm lớp nền tảng. Đây là một hệ thống tiêu chuẩn thực hiện một bước nhảy vọt mới thông qua giao dịch môi giới token.',
    ]
  }
};