<template>
  <van-nav-bar
      fixed
      :border="false"
      @click-left="$router.go(pageNumber)"
  ><img src="~@/assets/img/skinbitcoin/back.png" slot="left"><div class="title" slot="left">{{title}}</div></van-nav-bar>
</template>

<script>

export default {
  name: "NarBar",
  components: {},
  props: {
    title: '',
    pageNumber: '',
  },
}
</script>

<style scoped>
.van-nav-bar {
  background-color:  #000000 !important;
}

.title {
  margin-left: 12px;
  height:19px;
  font-size:17;
  color:#fff;
  text-align:left;
  opacity:1;
}
</style>
