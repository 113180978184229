import store from "@/store";
import router from "@/router";
import axios from "./Axios";
import $Dialog from "./Dialog";
import i18n from "@/i18n";

const model = {
  GetBackData(callback) {
    const getData = ({ data }) => {
      store.dispatch("UpdateInitData", data.info || "");
      // 获取排行榜首页会员榜单
      // model.RobotRanking((datas) => {
      //   data.info.memberList = datas.data.data;
      //   console.log(data);
      //   store.dispatch("UpdateInitData", data.info || "");
      // });
      localStorage["UploadApi"] = data.info.setting
        ? data.info.setting.up_url
        : "";
      localStorage["AppUrl"] = data.info.setting
        ? data.info.setting.app_url
        : "";
      callback && callback(data.info);
    };
    axios
      .post("Common/BackData", "", {
        noLogin: true,
      })
      .then(getData);
  },
  CommonData3(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Common/tiaiv3").then(getData);
  },
  GetCommonDataUrl(json,callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Common/dataUrl",json).then(getData);
  },
  GetCommonData(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Common/CommonData","").then(getData);
  },
  GetKlineData(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Common/klinedata","").then(getData);
  },
  GetRechargeData(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Common/RechargeData","").then(getData);
  },
  Login(json, callback) {
    const getData = ({ data }) => {
      // console.log(data)
      if (data.code == 1) {
        localStorage["MiName"] = data.info.username;
        localStorage["Token"] = data.info.token;
        localStorage["UserId"] = data.info.userid;
        localStorage["UId"] = data.info.userid;
        store.dispatch("UpdateUserInfo", data.info);
      }
      callback && callback(data);
    };
    axios
      .post("User/Login", json, {
        noLogin: true,
      })
      .then(getData);
  },
  Logout(callback) {
    const getData = ({ data }) => {
      if (data.code == 1) {
        localStorage.removeItem("Token");
        localStorage.removeItem("UserInfo");
        localStorage.removeItem("BankCardList");
        store.dispatch("UpdateUserInfo", "");
        store.dispatch("UpdateBankCardList", []);
        this.GetBackData();
        router.replace("/login");
      }
    //  $Dialog.Toast(data.code_dec);
    };
    axios.post("User/Logout").then(getData);
  },
  ResetPwd(json,callback) {
    const getData = ({ data }) => {
      callback && callback(data);
      // $Dialog.Toast(data.code_dec);
    };
    axios.post("User/resetPassword",json).then(getData);
  },
  TradeStatus(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("hall/getTradeStatus").then(getData);
  },
  GetMsg(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/getMsg").then(getData);
  },
  CanAccess(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("common/allowip").then(getData);
  },
  UserRegister(json, callback) {
    $Dialog.Loading(i18n.t("dialog[5]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        model.Login({
          username: json.username,
          password: json.password,
        });
      }
      callback && callback(data);
      // $Dialog.Toast(data.code_dec);
    };
    axios
      .post("user/Register", json, {
        noLogin: true,
      })
      .then(getData);
  },
  UserFaceData(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/Facedata", json).then(getData);
  },
  UserVerify(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/Verify", json).then(getData);
  },
  ClientLog(logKey, logTitle, logData) {
    var json = {
      logKey: logKey,
      logTitle: logTitle,
      logData: logData,
    };
    axios.post("spam/clientLog", json);
  },
  SmsCode(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("sms/smsCode", "", {
        noLogin: true,
      })
      .then(getData);
  },
  GetSMSCode(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("sms/sendSMSCode", json, {
        noLogin: true,
      })
      .then(getData);
  },
  GetRegCode(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
        .post("sms/sendRegCode", json, {
          noLogin: true,
        })
        .then(getData);
  },
  GetUserInfo(callback) {
    const getData = ({ data }) => {
      if (data.code == 1) {
        store.dispatch("UpdateUserInfo", data.info);
      }
      callback && callback(data);
    };
    axios.post("user/getUserInfo").then(getData);
  },
  GobusAuth(json, callback) {
    // console.log('SetUserInfo json',json)
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
     // $Dialog.Toast(data.code_dec);
    };
    axios.post("user/busauth", json).then(getData);
  },
  GoShimingAuth(json, callback) {
    // console.log('SetUserInfo json',json)
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
    };
    axios.post("user/shimingauth", json).then(getData);
  },
  GobindAccount(json, callback) {
    // console.log('SetUserInfo json',json)
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
     // $Dialog.Toast(data.code_dec);
    };
    axios.post("user/bindaccount", json).then(getData);
  },
  SetUserInfo(json, callback) {
    // console.log('SetUserInfo json',json)
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      //$Dialog.Toast("Ödeme şifresi başarıyla ayarlandı");
    };
    axios.post("user/setuserinfo", json).then(getData);
  },
  CreateOrder(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        router.push("/user/invest");
        this.GetUserInfo();
      }
      callback && callback(data);
      //$Dialog.Toast(data.code_dec);
    };
    axios.post("Order/createOrder", json).then(getData);
  },
  OrderList(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Order/Orderlist").then(getData);
  },
  OrderRecordList(id, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("Order/orderRecordList", {
        orderid: id,
      })
      .then(getData);
  },
  FundDetails(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Transaction/FundDetails", json).then(getData);
  },
  GetDrawRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Transaction/getDrawRecord", json).then(getData);
  },
  Draw(json, callback) {
    // $Dialog.Loading(i18n.t('dialog[2]'));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      // $Dialog.Toast(data.code_dec);
    };
    axios.post("Transaction/draw", json).then(getData);
  },
  //领取幸运包
  getLuckyPack(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Luckypack/pickLuckyBag", json).then(getData);
  },
  Transfer(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetUserInfo();
      }
      callback && callback(data);
      //$Dialog.Toast(data.code_dec);
    };
    axios.post("Transaction/Transfer", json).then(getData);
  },
  AddBankCard(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetBankCardList();
      }
      callback && callback(data);
    };
    axios.post("Account/AddBankCard", json).then(getData);
  },
  GetBankCardList(callback) {
    const getData = ({ data }) => {
      store.dispatch("UpdateBankCardList", data.data || []);
      let BankCardNum = { u: 0, b: 0 }
      for (let x in data.data) {
        // console.log('bbb',x)
        if (data.data[x].currency == 'USDT') {
          BankCardNum.u++
        } else {
          BankCardNum.b++
        }
      }
      window.localStorage.setItem("BankCardNumU", BankCardNum.u);
      window.localStorage.setItem("BankCardNumB", BankCardNum.b);


      callback && callback(data);
    };
    axios.post("Account/getBankCardList").then(getData);
  },
  GetRechargeRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Transaction/getRechargeRecord", json).then(getData);
  },
  GetRechargeType(callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios
      .post("Transaction/getRechargetype", {
        type: "app",
      })
      .then(getData);
  },
  RechargeOrder(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      //$Dialog.Toast(data.code_dec);
    };
    axios.post("Recharge/newRechargeOrder", json).then(getData);
  },
  GetRechargeInfo(orderId, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("Recharge/getRechargeInfo", {
        orderNumber: orderId,
      })
      .then(getData);
  },
  SetOrderInfo(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      //$Dialog.Toast(data.code_dec);
    };
    axios.post("Recharge/setOrderInfo", json).then(getData);
  },
  UploadImg(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post('User/uploadImg', json).then(getResponse);
  },
  BuyVip(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
     // $Dialog.Toast(data.code_dec);
    };
    axios.post("User/userBuyVip", json).then(getResponse);
  },
  PostTask(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
     // $Dialog.Toast(data.code_dec);
    };
    axios.post("task/publishTask", json).then(getResponse);
  },
  GetRobotTasks(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("hall/getRobotTasks", json).then(getResponse);
  },
  GetHallTaskList(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("hall/getTaskList", json).then(getResponse);
  },
  GetTaskList(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/getTaskList", json).then(getResponse);
  },
  GetUserTaskList(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("usertask/getTaskList", json).then(getResponse);
  },
  //领取任务
  ReceiveTask(id, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
     // $Dialog.Toast("Limite diário excedido");
    };
    axios
        .post("task/receiveTask", {
          id: id,
        })
        .then(getResponse);
  },
  //完成任务
  FinishTask(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("task/finishTask", json)
      .then(getResponse);
  },
  GetTaskOrderinfo(id, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios
        .post("task/taskOrderInfo", {
          id: id,
        })
        .then(getResponse);
  },
  GetTaskinfo(id, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("task/getTaskinfo", {
        id: id,
      })
      .then(getResponse);
  },
  GetTaskRecord(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/taskOrderlist", json).then(getResponse);
  },
  SubmitTask(json, callback) {
    /*  $Dialog.Loading(i18n.t("dialog[2]")); */
    const getResponse = ({ data }) => {
      callback && callback(data);
      /* $Dialog.Toast(data.code_dec); */
    };
    axios.post("task/taskSubmit", json).then(getResponse);
  },
  SubmitTaskOrder(json, callback) {
    /*  $Dialog.Loading(i18n.t("dialog[2]")); */
    const getResponse = ({ data }) => {
      callback && callback(data);
      /* $Dialog.Toast(data.code_dec); */
    };
    axios.post("usertask/submitTask", json).then(getResponse);
  },
  CancelTask(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("task/revokeTask", json)
      .then(getResponse);
  },
  TaskOrderInfo(id, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("task/taskorderinfo", {
        order_id: id,
      })
      .then(getResponse);
  },
  AuditTask(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getResponse = ({ data }) => {
      callback && callback(data);
      //$Dialog.Toast(data.code_dec);
    };
    axios.post("task/taskOrderTrial", json).then(getResponse);
  },
  DailyReport(callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("User/dailyReport").then(getResponse);
  },
  CreditList(callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/getUserCreditList").then(getResponse);
  },
  TeamReport(json, callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getResponse = ({ data }) => {
      $Dialog.Close();
      callback && callback(data);
    };
    axios.post("user/teamReport", json).then(getResponse);
  },
  GetStatisticsInfo(callback) {
    const getResponse = ({ data }) => {
      if (data.code == 1) {
        store.dispatch("UpdateStatisticalData", data.info);
      }
      callback && callback(data);
    };
    axios.post("user/getStatisticsInfo").then(getResponse);
  },
  GetPayBank(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Account/GetPayBankCode", json).then(getResponse);
  },
  GetRegisterKey(json, callback) {
    // console.log(data)
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    // console.log(getResponse)
    axios.post("Spam/getRegisterKey", json).then(getResponse);
  },
  ReqFaceVerify(json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Spam/reqFaceVerify", json).then(getResponse);
  },
  SubmitUrl(url, json, callback) {
    const getResponse = ({ data }) => {
      callback && callback(data);
    };
    // axios.post(url, json).then(getResponse);
    console.log(url);
    axios.post("pay/gateway/index", json).then(getResponse);
  },
  GetWithdrawType(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios
      .post("Transaction/getDrawtype", {
        type: "app",
      })
      .then(getData);
  },
  ChangeBankCardInfo(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      if (data.code == 1) {
        this.GetBankCardList();
      }
      callback && callback(data);
     // $Dialog.Toast(data.code_dec);
    };
    axios.post("Account/changeBankCardInfo", json).then(getData);
  },
  TeamSummary(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("UserAgent/teamSummary", json).then(getData);
  },
  Performance(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("UserAgent/performance", json).then(getData);
  },
  Commission(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("UserAgent/commission", json).then(getData);
  },
  //vip等级
  VipRule(callback) {
    // $Dialog.Loading(i18n.t('dialog[6]'));
    const getData = ({ data }) => {
      store.dispatch("UpdateVipRuleList", data.data || []);
      // $Dialog.Close();
      callback && callback(data);
    };
    axios.post("vip/rule").then(getData);
  },
  //vip轮播
  VipFlow(callback) {
    store.state.VipFlowList ? "" : $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      store.state.VipFlowList ? "" : $Dialog.Close();
      callback && callback(data);
      let datas = data;
      datas.data = datas.data.splice(0, 10);
      store.dispatch("UpdateVipFlowList", datas.data || []);
    };
    axios.post("vip/flow").then(getData);
  },
  //某用户详情
  RobotUser(json, callback) {
    store.state.InitData ? "" : $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      store.state.InitData ? "" : $Dialog.Close();
      callback && callback(data);
    };
    axios.post("robot/user", json).then(getData);
  },
  //首提分享金
  UserPropList(callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      $Dialog.Close();
      callback && callback(data);
    };
    axios.post("user/propList").then(getData);
  },
  //活动中心
  ActivityGetList(callback) {
    store.state.ActivityCenterList ? "" : $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      store.state.ActivityCenterList ? "" : $Dialog.Close();
      callback && callback(data);
      let datas = data;
      datas.data.rolling = datas.data.rolling.splice(0, 10);
      store.dispatch("UpdateActivityCenterList", datas.data || []);
    };
    axios.post("activity/getList").then(getData);
  },
  //活动详情
  ActivityGet(json, callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      store.dispatch("UpdateActivityGetData", data.data || []);
      $Dialog.Close();
      callback && callback(data);
    };
    axios.post("activity/get", json).then(getData);
  },
  //活动签到
  UserSignin(callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      $Dialog.Close();
      callback && callback(data);
    };
    axios.post("user/signin").then(getData);
  },
  //写入分享日志
  UserWriteShareLog(json, callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      $Dialog.Close();
      callback && callback(data);
    };
    axios.post("user/writeShareLog", json).then(getData);
  },
  // 首页获取会员榜单接口
  RobotRanking(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    let json = {
      page: 1,
      limit: 20,
    };
    axios.post("robot/ranking", json).then(getData);
  },
  // 用户历史记录
  RobotHistory(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("robot/history", json).then(getData);
  },
  // 我的道具详情
  UserPropInfo(json, callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("user/propInfo", json).then(getData);
  },
  // 领取道具
  UserReceivePropReward(json, callback) {
    $Dialog.Loading(i18n.t("dialog[6]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("user/receivePropReward", json).then(getData);
  },
  // 发送留言
  RobotSend_message(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("robot/send_message", json).then(getData);
  },
  // 获取留言
  RobotGet_message(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("robot/get_message", json).then(getData);
  },
  // 获取新手任务 - 礼包列表
  getMyNoviceTask(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("task/getmynovicetask", json).then(getData);
  },
  // 获取新手任务 - 任务列表，任务从属于礼包
  getNoviceTaskList(json, callback) {
    $Dialog.Loading(i18n.t("dialog[2]"));
    const getData = ({ data }) => {
      callback && callback(data);
      $Dialog.Close();
    };
    axios.post("task/getNoviceTaskList", json).then(getData);
  },
  //获取下级列表
  getInferiorList(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/theUsersIInvited", json).then(getData);
  },
  //领取新手礼包
  receiveNoviceGift(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/receiveNoviceGift", json).then(getData);
  },
  //领取邀请活动奖励
  receiveProp(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("user/receiveProp", json).then(getData);
  },
  //邀请下级领取奖励
  getUpgradeAmount(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("User/getUpgradeAmount").then(getData);
  },
  //已领的商城任务
  getTodayShopTask(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/getTodayShopTask").then(getData);
  },
  //获取领取的当前包中的任务
  shopDispatchDetail(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/shopDispatchDetail").then(getData);
  },
  //接取随机商城任务
  acceptRandomShopTask(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/acceptRandomShopTask").then(getData);
  },
  //商城任务 - 一次领取全部任务
  acceptDispatchTask(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Task/acceptDispatchTask").then(getData);
  },
  //查询完成全部任务后 是否领取幸运包
  receiveLuckyPag(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Luckypack/checkLuckyPack").then(getData);
  },
  //商城任务 - 完成以前的任务
  finishShopTask(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/finishShopTask", json).then(getData);
  },
  //商城任务 - 完成现在的任务
  finishDispatchTask(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/finishDispatchTask", json).then(getData);
  },
  GetFinConfigData(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("fin/getConfig", json).then(getData);
  },
  GetFinSumm(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("fin/summdata", json).then(getData);
  },
  GetFinData(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("fin/findata", json).then(getData);
  },
  addFin(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("fin/addFin", json).then(getData);
  },
  outFin(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("fin/outFin", json).then(getData);
  },
  /* 手动抢单 */
  manualOrderGrabbing(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("qiangd/ManualGrabOrders", json).then(getData);
  },
  //获取抢单记录
  getOrderRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("task/taskTodayrenwu", json).then(getData);
  },
  //幸运礼包签名后
  sign(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("Luckypack/sign", json).then(getData);
  },
  //自动抢单
  zidong(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("qiangd/zidongQd", json).then(getData);
  },
  //获取开通自动抢单页面 内容
  getOpenzdContent(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("qiangd/getAbility", json).then(getData);
  },
  //自动完成
  autoComplete(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("qiangd/autoFinish", json).then(getData);
  },
  //获取开通自动完成任务页面 内容 qiangd/getAutoAbility
  getOpenzdSContent(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("qiangd/getAutoAbility", json).then(getData);
  },
  //获取 代理中心 团队数据  useragent/teamSummary
  getTeamData(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("useragent/teamSummary", json).then(getData);
  },
  //获取业绩 get results
  getResults(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("useragent/performance", json).then(getData);
  },
  //获取佣金记录
  getCommissionRecord(json, callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("useragent/commission", json).then(getData);
  },
  //获取提现成弹窗信息 setting/firstwindow
  getwithdrawal_info(callback) {
    const getData = ({ data }) => {
      callback && callback(data);
    };
    axios.post("setting/firstwindow").then(getData);
  },
};

export default model;
