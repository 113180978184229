<template>
  <div>
    <div class="top-nav" v-click-outside="handleBlur">
      <div class="sibeBarTitle" @click="sibeBar" style="width: 38px; padding-top: 3px;">
        <img style="width: 18px; height: 15px;" src="~@/assets/img/skinbitcoin/bar.png" alt="">
      </div>
      <div class="sibeBarTitle" :style="$route.path.toString()=='/'||$route.path.includes('/home')?'margin-left: 20px;width: 140px; color:#ffffff;':'margin-left: 20px;width: 140px;'" @click="$router.push('/')">{{ $t('sidebar[0]') }}</div>
      <div class="sibeBarTitle" :style="$route.path.toString().includes('/hall')?'color:#ffffff;':''" @click="$router.push('/hall')">{{ $t('sidebar[1]') }}</div>
      <!--<div>
        <img style="position:relative; bottom:4px; right: 36px; width: 24px;" src="~@/assets/img/skinbitcoin/righticon1.png"
             @click="gotoMsgList()">
      </div>-->
      <div>
        <img style="position:relative; bottom:4px; right: 28px; width: 28px;border-radius: 34px; " src="~@/assets/img/skinbitcoin/customer.png"
             @click="gotoServiceCenter()">
      </div>
      <div v-if="toke != null">
        <img
            style="position:relative; bottom:4px; right: 16px; width: 30px;height: 30px;border-radius: 34px; border: 2px solid #fff;"
            :src="avatar" @click="goUser()">
      </div>
    </div>
    <div v-if="showSibeBar" style="width: 100%; height: 100%; z-index: 996;position: fixed;">
      <div v-if="showSibeBar" class="sibeBar">
        <van-sidebar :activeKey="activeKey" @change="onChange">
          <div v-for="item in sibeBarList">
            <div style="display: flex; align-items: center; background-color: #0b273f; padding-left: 6px;">
              <img style="margin-right: -6px;z-index: 99; width: 12px; height: 12px;" :src="item.icon">
              <div style="display: flex; align-items: center; width: 90%;justify-content: space-between;">
                <div><van-sidebar-item :title="item.name"/></div>
                <div style="text-align: end;">
                  <img  v-if="lang=='en'" :src="item.rightIcon">
                </div>
              </div>
            </div>
          </div>
        </van-sidebar>
      </div>
    </div>
    <van-action-sheet v-model="showLanguage" :title="$t('login.text[0]')" :style="{backgroundColor: '#0b273f',color: '#FFFFFF'}">
      <div class="content">
        <div @click="changeLanguage('en-US',1)" class="l_item" :class="parseInt(radio)==1?'active_l_item':''">English</div>
        <div @click="changeLanguage('vn',2)" class="l_item" :class="parseInt(radio)==2?'active_l_item':''">Tiếng Việt</div>
        <div @click="changeLanguage('zh-CN',3)" class="l_item" :class="parseInt(radio)==3?'active_l_item':''">简体中文</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// 自定义指令，用于处理点击外部区域的事件
import router from "../router";

const clickOutside = {
  bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法
        binding.value(event);
      }
    };
    // 在文档上添加点击事件监听器
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

var that;
export default {
  name: "SibeBar",
  components: {},
  directives: {
    "click-outside": clickOutside,
  },
  props: {
    index: 0,
    avatar: '',
  },
  data() {
    return {
      showLanguage:false,
      radio:'',
      navTitle: 'Trading Hall',
      sibeBarList: [
        {name: this.$t('sidebar[0]'), page: '/',icon: require('@/assets/img/nav/home.png')},
        {name: this.$t('sidebar[1]'), page: '/hall',icon: require('@/assets/img/nav/hall.png')},
        {name: this.$t('sidebar[2]'), page: '/user/wallet',icon: require('@/assets/img/nav/myassets.png')},
        {name: this.$t('sidebar[3]'), page: '/user/bindAccount',icon: require('@/assets/img/nav/abinding.png')},
        {name: this.$t('sidebar[4]'), page: '/user/fundRecord',icon: require('@/assets/img/nav/assetlog.png')},
        {name: this.$t('sidebar[5]'), page: '/user/teamReport',icon: require('@/assets/img/nav/teamlist.png')},
        {
          name: this.$t('sidebar[6]'), page: '', callback: function () {
            that.$router.push('/user/invite/' + that.UserInfo.idcode + '/' + that.UserInfo.idcode);
          },icon: require('@/assets/img/nav/invitation.png')
        },
        {name: this.$t('sidebar[7]'), page: '/appDown',icon: require('@/assets/img/nav/22853.png')},
        {
          name: this.$t('sidebar[8]'), page: '', callback: function () {
            that.showLanguage=true;
          },icon: require('@/assets/img/nav/language.png'), rightIcon: require('@/assets/img/skinbitcoin/11786.png')
        },
        //{name:this.$t('sidebar[9]'),page:''},
        {name: this.$t('sidebar[10]'), page: '/help',icon: require('@/assets/img/nav/platform.png')},

      ],
      activeKey: 0,
      showSibeBar: false,
      lang: localStorage["Language"],
      toke: null,
    }
  },
  created() {
    that = this;
    this.activeKey = this.index;
    if (this.index != 0) {
      this.navTitle = this.sibeBarList[this.activeKey].name;
    }
    this.toke = localStorage.getItem("Token");
    if(this.toke != null) {
      this.sibeBarList.push({
        name: this.$t('sidebar[11]'), page: '', callback: function () {
          that.$Model.Logout();
        },icon: require('@/assets/img/nav/signout.png')
      })
    }
  },
  mounted() {
    this.$Model.GetBackData((data) => {
      var setting = data.setting;
      this.service_url = setting.service_url2;
    });
  },
  methods: {
    router() {
      return router
    },
    onChange(event) {
      // 设置语言
      if(event == 8) {
        this.sibeBarList[8].callback();
        return;
      }
      if (this.activeKey != 0) {
        this.navTitle = this.sibeBarList[this.activeKey].name;
      }
      this.activeKey = event;
      if (typeof this.sibeBarList[this.activeKey].callback != 'undefined') {
        this.sibeBarList[this.activeKey].callback();
      } else {
        this.$router.push(this.sibeBarList[this.activeKey].page);
      }
      this.showSibeBar = false;

    },
    sibeBar() {
      if (this.showSibeBar) {
        this.showSibeBar = false;
      } else {
        this.showSibeBar = true;
      }
    },
    //点击其它区域
    handleBlur(event) {
      //console.log("点击其它区域啦",event);
      if (this.showSibeBar) {
        this.showSibeBar = false;
      }
    },
    goUser() {
      this.$router.push('/user');
    },
    gotoMsgList() {
      this.$router.push("/msglist");
    },
    gotoServiceCenter() {
      this.$router.push("/serviceCenter");
    },
    selectLan() {
      let a = localStorage.getItem("Language");
      switch (a) {
        case "en":
          this.radio = "1";
          // console.log("11111");
          break;
        case "vn":
          this.radio = "2";
          break;
        case "cn":
          this.radio = "3";
          break;
        case "tc":
          this.radio = "4";
          break;
        case "fr":
          this.radio = "5";
          break;
      }
    },
    changeLanguage(lan,select) {
      this.radio = select;
      this.$SetLanguage(lan);
      this.selectLan();
      this.showLanguage = false;
    },
  }
}
</script>
<style scoped>
.top-nav {
  position: fixed;
  padding-top: 14px;
  padding-left: 23px;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  background-color: #000000;
  z-index: 997;
}

.sibeBar {
  position: fixed;
  left: 23px;
  z-index: 999;
}

.sibeBarTitle {
  width: 100%;
  height: 20px;
  font-size: 15px;
  color: #9d9d9d;
  text-align: left;
  opacity: 1;
}

.van-sidebar {
  position: relative;
  top: 50px;
  width: 254px;
  line-height: 54px;
}

.van-sidebar-item {
  background-color: #0b273f;
  color: #FFFFFF;
  line-height: 0;
  font-size: 15px;
}

.van-sidebar-item--select {
  background-color: #0b273f;
  color: #FFFFFF;
}

/*van-sidebar-item--select::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 16px;
  background-color: #ee0a24;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}*/

.content{
  padding: 60px 0;
  background-color: #0b273f;
  color: #ffffff;
}
.l_item{
  font-size: 18px;
  padding: 10px ;
  text-align: center;
}

.van-sidebar-item::before {
  background-color: #0b273f;
}

</style>
